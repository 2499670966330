import { IPhoenixModule } from '@/plugins/phoenix/library';
import { ILink, IView, MenuItem } from '@/plugins/phoenix/types';
import Vue from 'vue';

// Components
Vue.component('LoginDialog', () => import(/* webpackChunkName: "sessionDialogs" */ './dialogs/LoginDialog.vue'))
Vue.component('PwdChangeDialog', () => import(/* webpackChunkName: "sessionDialogs" */ './dialogs/PwdChangeDialog.vue'))
Vue.component('InitProfile', () => import(/* webpackChunkName: "sessionDialogs" */ './dialogs/InitProfile.vue'))
Vue.component('ForgetPassword', () => import(/* webpackChunkName: "sessionDialogs" */ './dialogs/ForgetPassword.vue'))

let moduleSession: IPhoenixModule = {
  name(): string {
    return 'session'
  },

  routes(): Array<ILink> {
    return [];
  },

  userMenu(): Array<MenuItem> {
    return [];
  },

  appMenu(): Array<MenuItem> {
    return [];
  },

  toolbar(): Array<MenuItem> {
    return [];
  },

  reports(): Array<IView> {
    return [];
  },

  async init(): Promise<any> {
  },
}

export default moduleSession;
